$(document).ready(function () {
    $('ul.nav-hover li.dropdown').hover(function () {
        $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
    }, function () {
        $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
    });

    jQuery.extend(jQuery.validator.messages, jqueryValidateErrorMessages);

    $('.contact_from').validate({
        errorPlacement: function(error, element) {
            error.appendTo( element.parent(".input-group").next(".errorTxt") );
        }
    });
});



